<template>
  <ion-page id="characters-page" class="page scrollable bg-transparent">
    <h1><strong>Characters</strong></h1>
    <characters-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" />
    <infinite-characters class="my-2" :current-tab="currentTab" />
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action } from 's-vuex-class';
import CharactersTabs from './components/CharactersTabs.vue';
import InfiniteCharacters from './components/InfiniteCharacters.vue';
import { charactersTabs } from '@/shared/statics/tabs';
import namespace from '@/shared/store/namespace';
import { Tab } from '@/shared/types/static-types';



@Options({
  name: 'CharactersPage',
  components: {
    CharactersTabs,
    InfiniteCharacters,
  },
})
export default class CharactersPage extends Vue {
  public tabs = charactersTabs;
  public currentTab: string = 'trending';
  public tabIndex = 0;

  @Action('getTrendingCharacters', { namespace: namespace.CharactersModule })
  public getTrendingCharacters!: any;

  @Action('getLatestCharacters', { namespace: namespace.CharactersModule })
  public getLatestCharacters!: any;

  @Action('getTopCharacters', { namespace: namespace.CharactersModule })
  public getTopCharacters!: any;

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    this.currentTab = value;
    router.replace({ name: 'characters', query: { tab: value } });
  }

  public async created() {
    const router = useRouter();
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    this.getTrendingCharacters({});
    this.getLatestCharacters({});
    this.getTopCharacters({});

    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === router.currentRoute.value.query.tab)
    );

    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = router.currentRoute.value.query.tab;
    }
  }
}
</script>
<style lang="sass" scoped>
.infinite-home
  --background: transparent
  height: calc(100% - 58px - 40px)
  overflow: auto
  &::-webkit-scrollbar
    display: none
</style>
