<template>
  <div>
    <div v-if="charactersToList && charactersToList.length">
      <character-card-small
        v-for="character of charactersToList"
        :key="character.id"
        :character="character"
        :comments-count="get(charCommentsCounts, `${character.id}.comments_count`) || 0"
        :show-comment-counts="true"
        class="my-3"
      />
      <p v-show="nextPageExists" class="clickable" @click="requestLoadMore">Load More</p>
      <p v-show="!nextPageExists">End of list</p>
    </div>
    <div v-else class="no-data">No characters yet</div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Action, Getter } from 's-vuex-class';
import CharacterCardSmall from '@/shared/components/CharacterCardSmall.vue';
import namespace from '@/shared/store/namespace';
import { Character } from '@/shared/types/static-types';

import { getCommentsCount } from '@/shared/actions/comments';
import constants from '@/shared/statics/constants';

@Options({
  name: 'InfiniteCharactersHome',
  components: {
    CharacterCardSmall,
  },
})
export default class InfiniteCharacters extends Vue {
  @Prop() public currentTab!: string;
  public charCommentsCounts = {};
  public areCommentCountsLoading = false;

  get = get;

  public get charactersToList() {
    if (this.currentTab === 'trending') return this.trendingCharacters;
    else if (this.currentTab === 'top') return this.topCharacters;
    else return this.latestCharacters;
  }

  @Watch('charactersToList')
  charactersToListChanged() {
    if (get(this.charactersToList, 'length')) {
      this.fetchCharsCommentCounts();
    }
  }

  // when currentTab is trending
  @Getter('trendingCharacters', { namespace: namespace.CharactersModule })
  public trendingCharacters!: Character[];

  @Getter('trendingCharactersPaging', { namespace: namespace.CharactersModule })
  public trendingCharactersPaging!: any;

  @Action('trendingCharactersNextPage', { namespace: namespace.CharactersModule })
  public trendingCharactersNextPage!: any;

  // when currentTab is latest
  @Getter('latestCharacters', { namespace: namespace.CharactersModule })
  public latestCharacters!: Character[];

  @Getter('latestCharactersPaging', { namespace: namespace.CharactersModule })
  public latestCharactersPaging!: any;

  @Action('latestCharactersNextPage', { namespace: namespace.CharactersModule })
  public latestCharactersNextPage!: any;

  // when currentTab is top
  @Getter('topCharacters', { namespace: namespace.CharactersModule })
  public topCharacters!: Character[];

  @Getter('topCharactersPaging', { namespace: namespace.CharactersModule })
  public topCharactersPaging!: any;

  @Action('topCharactersNextPage', { namespace: namespace.CharactersModule })
  public topCharactersNextPage!: any;

  public get nextPageExists() {
    if (this.currentTab === 'trending') return !!this.trendingCharactersPaging.next;
    else if (this.currentTab === 'top') return !!this.topCharactersPaging.next;
    else return !!this.latestCharactersPaging.next;
  }

  public async fetchCharsCommentCounts() {
    this.areCommentCountsLoading = true;
    this.charCommentsCounts = {};
    const resp = await getCommentsCount(map(this.charactersToList, 'id'), constants.commentedAsTypes.CHARACTER);
    this.charCommentsCounts = keyBy(get(resp, 'counts', []), 'commented_on_object_id');
    this.areCommentCountsLoading = false;
  }

  public async requestLoadMore(ev: CustomEvent) {
    if (this.currentTab === 'trending') {
      if (!this.trendingCharactersPaging.next) {
        (ev?.target as any).complete();
      } else {
        await this.trendingCharactersNextPage();
      }
    } else if (this.currentTab === 'top') {
      if (!this.topCharactersPaging.next) {
        (ev?.target as any).complete();
      } else {
        await this.topCharactersNextPage();
      }
    } else if (!this.latestCharactersPaging.next) {
      (ev?.target as any).complete();
    } else {
      await this.latestCharactersNextPage();
    }
  }
}
</script>

<style scoped lang="sass"></style>
